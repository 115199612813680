import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import Styled, { css } from "styled-components"
import { Link } from "gatsby"

const Container = Styled.div`
  height: 100vh;
  @media all and (min-width: 992px) {
    margin-top: 0;
    padding: 0 155px;
  }
  .slick-slider {
    display: flex;
  }
`

const StyledSlider = Styled(Slider)`
  height: 100%;
  margin-top: 50px;
  .slick-track {
    display: flex;
    height: -webkit-fill-available;
    align-items: center;
  }

  .slick-active {
    z-index: 10;
  }
`

const StyledSlick = Styled(Link)`
  outline: none;
  padding: 0 15px;
  @font-face {
    font-family: "sambal-light";
    src: url("Sambal-Light.otf");
    font-display: block;
  }
  h1, p, span {
    line-height: 1.2;
    font-family: sambal-light !important;
    color: ${props => props.theme.headerText}
  }
  h1 {
    font-size: ${props =>
      props.index === 1 ? "28px" : props.fontSize ? props.fontSize : "36px"};
  } 
  @media all and (min-width: 992px) {
    h1, p, span {
      white-space: pre-wrap;
    }
    h1 {
      font-size: ${props => (props.fontSize ? props.fontSize : "48px")};
    } 
  }
  //for iphone 5 size
  @media all and (max-height: 568px) {
        ${props =>
          props.index === 1 &&
          css`
            h1 {
              font-size: 25px;
            }
          `};
        ${props =>
          props.index === 8 &&
          css`
            h1 {
              font-size: 32px;
            }
          `};
  }
`

const IndexPage = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  }

  const contents = [
    {
      text: "Visible<br/>Brand Actions<span style='color: white'>™</span>",
      fontSize: "68px",
      to: "/visible-brand-actions",
    },
    {
      text:
        "They call it\n‘dark marketing’,<br/><span style='color: white'>I call it\n‘positioning brand\nin limelight’.</span>",
      author: "Balreet Gill, Founder<br/>Visible Brand Actions™",
      to: "/visible-brand-actions",
    },
    {
      text:
        "The quickest way\nto a 284% return\non investment?<br/><br/><span style='color: white'>Start with 0 guesswork.</span>",
      author:
        "Jodh Dheensay, Brand Assessment & Strategy<br/>JTI Trade Loyalty Programme",
      to: "/projects/jti-together-we-grow",
    },
    {
      text: "Visible<br/>Brand Actions<span style='color: white'>™</span>",
      fontSize: "70px",
      to: "/visible-brand-actions",
    },
    {
      text:
        "How do you get\nthe Robb Report to ever cover\nsomething like a beer fest?<br/><br/><span style='color: white'>Forget the word 'beer'.</span>",
      author: "Jeninder Gill, Public Relations<br/>The Great Brew Fest",
      to: "/projects/heineken's-the-great-brew-fest",
    },
    {
      text:
        "A client with\nno business strategy,\nno new product variant,\nnothing new to say.<br/>It's not so serious.<br/><br/><span style='color: white'>Seriously.</span>",
      author:
        "Jodh Dheensay, Brand Positioning<br/>Jameson Not-So-Serious Activation",
      to: "/projects/jameson-not-so-serious",
    },
    {
      text: "Visible<br/>Brand Actions<span style='color: white'>™</span>",
      fontSize: "70px",
      to: "/visible-brand-actions",
    },
    {
      text:
        "Want to get millennials\ninto a Cognac\nthey know nothing about?<br/><span style='color: white'>Make them the expert.</span>",
      author: "Jeninder Gill, Experiential PR<br/>Hennessy VS & VSOP Cocktail",
      to: "/projects/hennessy-vs-&-vsop-cocktail",
    },
    {
      text:
        "By the time they poured\ntheir own pint and sipped\nthrough the creamy foam\nwith elbow at 90 degrees,<br/><span style='color: white'>13,000 people had forgotten\nabout the other black beer.</span>",
      author:
        "Balreet Gill, Experiential Activation<br/>Guinness Taste Perfection",
      to: "/projects/guinness-taste-perfection",
    },
    {
      text:
        "An idea or action\npassed on to the next person\nmust become something else,\nwhich becomes another\nand interesting another,\nand this keeps going... evolving.\nLike energy, it does not end,\njust changes shape and form.<br/><span style='color: white'>This powerful approach,\nwhile it began as methodology,\nhas become integral in how\nwe operate on most levels.</span>",
      author: "Jodh, on The Process",
      fontSize: "32px",
      to: "/the-process",
    },
    {
      text:
        "An idea or action\npassed on to the next person\nmust become something else,\nwhich becomes another\nand interesting another,\nand this keeps going... evolving.\nLike energy, it does not end,\njust changes shape and form.<br/><span style='color: white'>This powerful approach,\nwhile it began as methodology,\nhas become integral in how\nwe operate on most levels.</span>",
      author: "Jodh, on The Process",
      fontSize: "32px",
      to: "/the-process",
    },
  ]

  return (
    <Layout>
      <SEO title="Home" />
      <Helmet>
        <title>Sambal Lab Malaysia | Brand Agency</title>
        <meta
          name="description"
          content="Visible Brand Actions™. In a world where consumer attention shifts by the minute, how do you earn loyalty? You break marketing tradition."
        />
      </Helmet>
      <Container>
        <StyledSlider {...settings}>
          {contents.map((content, index) => (
            <StyledSlick
              key={index}
              to={content.to}
              fontSize={content.fontSize}
              index={index}
            >
              <h1 dangerouslySetInnerHTML={{ __html: content.text }} />
              <p dangerouslySetInnerHTML={{ __html: content.author }} />
            </StyledSlick>
          ))}
        </StyledSlider>
      </Container>
    </Layout>
  )
}

export default IndexPage
